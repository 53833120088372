<!-- 还没到全 入库 -->
<template>
	<div class="yu_box_1">
		<div>


			<br />
			<el-row>
				<el-col class="yu_input_label" :span="6">
					订单id:
				</el-col>
				<el-col :span="18">
					<el-input v-model="m_baojia_info.ypdi_id" disabled></el-input>
				</el-col>
			</el-row>
			<br />
 
			<el-row>
				<el-col class="yu_input_label" :span="6">
					用户id:
				</el-col>
				<el-col :span="18">
					<el-input v-model="m_baojia_info.ypdi_user_id" disabled ></el-input>
				</el-col>
			</el-row>
			<br />
			
			 <el-row>
			 	<el-col class="yu_input_label" :span="6">
			 		样品产品id:
			 	</el-col>
			 	<el-col :span="18">
			 		<el-input v-model="m_baojia_info.ypdi_chanpin_id" disabled  ></el-input>
			 	</el-col>
			 </el-row>
			 <br />
			
			<el-row>
				<el-col class="yu_input_label" :span="6">
					样品产品型号:
				</el-col>
				<el-col :span="18">
					<el-input v-model="m_baojia_info.shpi_xinghao" disabled ></el-input>
				</el-col>
			</el-row>
			<br />
			
			<el-row>
				<el-col class="yu_input_label" :span="6">
					下单时间:
				</el-col>
				<el-col :span="18">
					<el-input v-model="m_baojia_info.ypdi_time" disabled ></el-input>
				</el-col>
			</el-row>
			<br />
						
			 
			
			<el-row>
				<el-col class="yu_input_label" :span="6">
					订单状态:
				</el-col>
				<el-col :span="18">
					<el-input v-model="m_baojia_info.ypdi_state" disabled ></el-input>
				</el-col>
			</el-row>
			<br />
			
			<el-row>
				<el-col class="yu_input_label" :span="6">
					样品收货地址:
				</el-col>
				<el-col :span="18">
					<el-input v-model="m_baojia_info.ypdi_dizhi" disabled ></el-input>
				</el-col>
			</el-row>
			<br />
			 
			 <el-row>
			 	<el-col class="yu_input_label" :span="6">
			 		样品收货人:
			 	</el-col>
			 	<el-col :span="18">
			 		<el-input v-model="m_baojia_info.ypdi_shoujianren" disabled ></el-input>
			 	</el-col>
			 </el-row>
			 <br />
			 
			 <el-row>
			 	<el-col class="yu_input_label" :span="6">
			 		样品收货人手机号:
			 	</el-col>
			 	<el-col :span="18">
			 		<el-input v-model="m_baojia_info.ypdi_shoujihao" disabled ></el-input>
			 	</el-col>
			 </el-row>
			 <br />
			<el-row>
				<el-col class="yu_input_label" :span="6">
					快递单号:
				</el-col>
				<el-col :span="18">
					<el-input v-model="m_baojia_info.ypdi_kuaidihao"  ></el-input>
				</el-col>
			</el-row>
			<br />
			 
			 <el-row>
			  	<el-col class="yu_input_label" :span="6">
			  		公司名称:
			  	</el-col>
			  	<el-col :span="18">
			  		<el-input v-model="m_baojia_info.ypdi_gongsiming" disabled ></el-input>
			  	</el-col>
			  </el-row>
			  <br />
			 <el-row>
			 	<el-col class="yu_input_label" :span="6">
			 		微信号:
			 	</el-col>
			 	<el-col :span="18">
			 		<el-input v-model="m_baojia_info.ypdi_weixinhao"  disabled></el-input>
			 	</el-col>
			 </el-row>
			 <br />
			 

			<br />
			<el-row class="yu_content_right">
				<el-button @click="my_dingdan_info_set" plain>点击提交</el-button>
			</el-row>
		</div>
	</div>
</template>

<script>
	import dingdan_info_get from "./expand/dingdan_info_get.js";
	import dingdan_info_post from "./expand/dingdan_info_post.js";

	export default {

		props: ["m_ding_id", "parent"],
		data() {
			return {
				m_baojia_info: [],
				m_zongjia : 0, 
				
				 
				m_state_list:[{
					state_id : 0,
					state_name : "待报价"
				},{
					state_id : 1,
					state_name : "未支付"
				},{
					state_id : 2,
					state_name : "支付成功"
				},{
					state_id : 3,
					state_name : "已发货"
				},{
					state_id : 4,
					state_name : "订单完成"
				},{
					state_id : 5,
					state_name : "支付失败"
				},{
					state_id : 6,
					state_name : "退款"
				}]

			}
		},
		created() {
			const that = this;

			console.log("3333");
			console.log(that.m_ding_id);

			that.dingdan_info_get = new dingdan_info_get(that);
			that.dingdan_info_get.m_main();
		},
		methods: {
			my_dingdan_info_set() {
				const that = this;
				that.dingdan_info_post = new dingdan_info_post(that);
				that.dingdan_info_post.m_main();
			},
			my_danjia_input(event){
				const that = this;
				console.log(event); 
				// this.inputValue = event.target.value;
				
				// return 0;
				that.m_zongjia = 0;
				for (let i = 0;i <that.m_baojia_info.length;i++) {
					that.m_zongjia +=  that.m_baojia_info[i]["diit_no"] * parseFloat(that.m_baojia_info[i]["diit_jiage"])
				}
			}
		}
	}
</script>


<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>